import React from "react";
import { graphql } from "gatsby";

import Divider1 from "../images/dividers/divider-3.svg";

const DatoAsset = ({ src, alt, className, style, lazy = true }) => (
    <picture>
        <source
            type="image/webp"
            srcSet={`${src}?fm=webp&w=200 200w,
${src}?fm=webp&w=400 400w,
${src}?fm=webp&w=800 800w,
${src}?fm=webp&w=1200 1200w,
${src}?fm=webp&w=1436 1436w`}
            sizes="(max-width: 800px) 100vw, 800px"
        />
        <source
            srcSet={`${src}?w=200 200w,
${src}?w=400 400w,
${src}?w=800 800w,
${src}?w=1200 1200w,
${src}?w=1436 1436w`}
            sizes="(max-width: 800px) 100vw, 800px"
        />
        <img
            sizes="(max-width: 800px) 100vw, 800px"
            srcSet={`${src}?fm=webp&w=200 200w,
${src}?w=400 400w,
${src}?w=800 800w,
${src}?w=1200 1200w,
${src}?w=1436 1436w`}
            src={`${src}?w=1200`}
            alt={alt || ""}
            className={className || ""}
            style={style || {}}
            loading={lazy ? "lazy" : "eager"}
        />
    </picture>
);

const FaqBlock = () => {
    const toggleAccordion = (e) => {
        const panel = e.target;
        const panelAccordionContent = panel.querySelector(`.accordion-content`);
        const panelIcon = panel.querySelector(".icon");

        if (panelAccordionContent.className.includes("collapse")) {
            panelAccordionContent.classList.remove("collapse");
            panelIcon.classList.add("icon-rotate");
        } else {
            panelAccordionContent.classList.add("collapse");
            panelIcon.classList.remove("icon-rotate");
        }
    };

    const accordionContent = [
        {
            id: "panel-1",
            question: "How does the DesignStream process work?",
            answer: (
                <ol className="mb-1">
                    <li className="mb-1">Once subscribed, you create a design task in our Notion board.</li>
                    <li className="mb-1">We acknowledge the task and start the work.</li>
                    <li className="mb-1">You receive the finished design.</li>
                </ol>
            ),
        },
        {
            id: "panel-2",
            question: 'What are "unlimited revisions" in DesignStream process?',
            answer: (
                <p>
                    Our "unlimited revisions" policy ensures your utmost satisfaction with our designs. Just like Rome wasn't built in a day, perfection takes
                    time. If you need changes after we've completed a request, simply let us know. We're happy to refine and adjust the design to meet your
                    exact needs. Feel free to make a new request with additional specifications at any time!
                </p>
            ),
        },
        {
            id: "panel-3",
            question: "What can be designed via DesignStream tasks?",
            answer: (
                <p>
                    We can deliver high-fidelity designs for specific components and elements of the interface for a given product, platform, or service.
                    Naturally, the complexity of the requirements may require additional time for a design. It's recommended to limit each design request to a
                    maximum of five requirements to ensure completion within a 2-day (or 4-day) window.
                </p>
            ),
        },
        {
            id: "panel-4",
            question: "Who are the UX specialists?",
            answer: (
                <p>
                    We have a dedicated and passionate team of UX designers and researchers with extensive experience in various domains including Fintech, AI,
                    E-commerce, Edtech, and more. We've collaborated with companies worldwide, honing our skills and delivering high-quality designs that stand
                    out in the competitive landscapes of numerous industries.
                </p>
            ),
        },
        {
            id: "panel-5",
            question: "Do you outsource the design tasks?",
            answer: (
                <p>
                    Never. We are all one Studio team that works full-time. There are no freelancers or temporary employees among us - we all consider ourselves
                    a part of the Studio and share the same values.
                </p>
            ),
        },
        {
            id: "panel-6",
            question: "Can I upgrade or downgrade my subscription?",
            answer: <p>Yes, just let us know, and the updated package becomes valid starting from the next month.</p>,
        },
        {
            id: "panel-7",
            question: "What if I don’t like the designs?",
            answer: <p>No problem! We can do revisions.</p>,
        },
    ];

    return (
        <section className="pb-4 o-hidden bg-primary-alt has-divider">
            <div className="divider bg-white">
                <Divider1 className="bg-primary-alt" />
            </div>
            <div className="container py-3">
                <div className="row mt-md-4 justify-content-center">
                    <div className="col-12 d-flex flex-column text-center">
                        <article id="article-content" className="article article-blog" style={{ zIndex: 9993 }}>
                            <h2 className="display-4 text-center">FAQ</h2>

                            {accordionContent.map((content) => (
                                <div
                                    key={content.id}
                                    onClick={toggleAccordion}
                                    style={{ cursor: "pointer" }}
                                    id={content.id}
                                    className="card card-sm card-body card--no-hover text-left"  /* border border-2 border-primary-3 */ 
                                >
                                    <div style={{ pointerEvents: "none" }} className="accordion-panel-title" aria-controls={content.id}>
                                        <p aria-controls={content.id}>
                                            <strong aria-controls={content.id}>{content.question}</strong>
                                        </p>
                                        <img className="icon" src="https://www.datocms-assets.com/16499/1583766454-plus.svg" aria-controls={content.id} />
                                    </div>
                                    <div style={{ pointerEvents: "none" }} className="accordion-content collapse">
                                        <div className="pt-3">{content.answer}</div>
                                    </div>
                                </div>
                            ))}
                        </article>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqBlock;
