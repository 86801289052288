import React from "react";
import { Link } from "gatsby";
import IconMail from "../images/icons/theme/communication/send.svg";
import { Helmet } from "react-helmet";
import IconCheck from "../images/icons/interface/check.svg";
import ImgCustom from "../images/photos/custom-ux-plan.jpg";
import { useLocation } from "../hooks/useLocation";

const SubscriptionBlock = () => {
    const { location, isLoading } = useLocation();

    return (
        <section id="subscriptionBlock" className="section-contacts section-contacts-block pt-0 pt-md-6 pb-6 position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-10 text-center mb-2 mb-md-6 mx-auto">
                        <h2 className="display-4">Our pricing and premium UX plans</h2>
                        <p className="col-xl-7 col-lg-8 col-md-10 mx-auto lead">
                            Experience seamless excellence with our premium subscription-based UX design services
                        </p>
                    </div>
                    
                    <div className="col-12 col-md-6 mb-4 ">
                        <div className="border-primary-3 border border-2 rounded pt-4 pb-2 px-3">
                            <h4>DesignStream Growth</h4>
                            {/* {isLoading ? (<p className="lead">Loading...</p>) 
                                : (
                                (location === "United States" || location === "Saudi Arabia" || location === "United Arab Emirates" || location === "Israel") ? (
                                    <div className="lead">
                                        <span className="opacity-70">$</span>
                                        <span className="ml-2 h2">9800</span>/mo
                                    </div>
                                ) : (
                                    <div className="lead">
                                        <span className="opacity-70">€</span>
                                        <span className="ml-2 h2">9200</span>/mo
                                    </div>
                                )
                            )}
                            <p className="mt-1">
                                No minimum commitment. <br />
                                Pause or cancel anytime
                            </p>
                            <div className="d-flex flex-column text-center pt-3 lead">
                                <Link to="https://calendly.com/adamfard/designstream" target="_blank" className="btn btn-lg btn-primary w-100 p-3 lead">
                                    Schedule a call
                                </Link>
                                <a href="/contact-us" target="_blank" className="lead text-primary mt-3">
                                    <u>Fill the form</u>
                                </a>
                             
                            </div> */}
                            {/* <div className="d-flex flex-column text-center pt-3 lead">
                                {
                                (location === "United States" || location === "Saudi Arabia" || location === "United Arab Emirates" || location === "Israel") ? (

                                    <Link to="https://buy.stripe.com/7sIg0w7QMbglfBu3ck" target="_blank" className="btn btn-lg btn-primary w-100 p-3 lead">
                                    Get Started
                                    </Link>

                                ):(
                                    <Link to="https://buy.stripe.com/28o4hO8UQ5W1fBu5kr" target="_blank" className="btn btn-lg btn-primary w-100 p-3 lead">
                                        Get Started
                                    </Link>
                                )}
                                    
                                <a href="https://calendly.com/adamfard/designstream" target="_blank" className="lead text-primary mt-3">
                                    <u>Book a call</u>
                                </a>
                            </div>
                            */}
                            {/* <p className="h4 primary-3 pt-6 font-weight-bold">What's included:</p>

                            <ul className="list-unstyled">
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <span>
                                        <strong className="lead font-weight-bold">Unlimited UX and UI tasks</strong>
                                        <p>Completed one at a time.</p>
                                    </span>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <p>
                                        <strong className="lead font-weight-bold">Unlimited Revisions</strong>
                                    </p>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <span>
                                        <strong className="lead font-weight-bold">1 Day Turnaround Time</strong>
                                        <p>
                                            On average for simple tasks.
                                            <br /> We provide estimates for each task
                                        </p>
                                    </span>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <span>
                                        <strong className="lead font-weight-bold">Daily Communication</strong>
                                        <p>Slack, Figma, Notion</p>
                                    </span>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <p>
                                        <strong className="lead font-weight-bold">1 meeting every week</strong>
                                    </p>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <span>
                                        <strong className="lead font-weight-bold">Project Management</strong>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                        <div className="border-primary-3 border border-2 rounded pt-4 pb-2 px-3">
                            <h4>DesignStream Lite</h4> */}

                            {isLoading ? (<p className="lead">Loading...</p>) 
                                : (
                                (location === "United States" || location === "Saudi Arabia" || location === "United Arab Emirates" || location === "Israel") ? (
                                    <div className="lead">
                                        <span className="opacity-70">$</span>
                                        <span className="ml-2 h2">5300</span>/mo
                                    </div>
                                ) : (
                                    <div className="lead">
                                        <span className="opacity-70">€</span>
                                        <span className="ml-2 h2">4900</span>/mo
                                    </div>
                                )
                            )}

                            <p className="mt-1">
                                No minimum commitment.
                                <br />
                                Pause or cancel anytime
                            </p>
                            <div className="d-flex flex-column text-center pt-3 lead">
                                <Link to="https://calendly.com/adamfard/designstream" target="_blank" className="btn btn-lg btn-primary w-100 p-3 lead">
                                    Schedule a call
                                </Link>
                                <a href="/contact-us" target="_blank" className="lead text-primary mt-3">
                                    <u>Fill the form</u>
                                </a>
                            </div>
                            {/* <div className="d-flex flex-column text-center pt-3 lead">
                            {(location === "United States" || location === "Saudi Arabia" || location === "United Arab Emirates" || location === "Israel") ? (
                                <Link to="https://buy.stripe.com/bIY15C7QMdot0GA8wF" target="_blank" className="btn btn-lg btn-primary w-100 p-3 lead">
                                    Get Started
                                </Link>
                                ) : (
                                    <Link to="https://buy.stripe.com/00gbKgc72esx0GA5kq" target="_blank" className="btn btn-lg btn-primary w-100 p-3 lead">
                                        Get Started
                                    </Link>
                                )}
                                
                                <a href="https://calendly.com/adamfard/designstream" target="_blank" className="lead text-primary mt-3">
                                    <u>Book a call</u>
                                </a>
                            </div> */}

                            <p className="h4 primary-3 pt-6 font-weight-bold">What's included:</p>

                            <ul className="list-unstyled">
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <span>
                                        <strong className="lead font-weight-bold">Unlimited UX and UI tasks</strong>
                                        <p>Completed one at a time.</p>
                                    </span>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <p>
                                        <strong className="lead font-weight-bold">Unlimited Revisions</strong>
                                    </p>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <span>
                                        <strong className="lead font-weight-bold">2 Days Turnaround Time</strong>
                                        <p>
                                            On average for simple tasks.
                                            <br /> We provide estimates for each task
                                        </p>
                                    </span>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <span>
                                        <strong className="lead font-weight-bold">Daily Communication</strong>
                                        <p>Slack, Figma, Notion</p>
                                    </span>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <p>
                                        <strong className="lead font-weight-bold">1 meeting every 2 weeks</strong>
                                    </p>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <span>
                                        <strong className="lead font-weight-bold">Project Management</strong>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mb-4 ">
                        <div className="border-primary-3 border border-2 rounded pt-4 pb-2 px-3">
                            <h4>DesignStream Lite</h4>
                            {isLoading ? (<p className="lead">Loading...</p>) 
                                : (
                                (location === "United States" || location === "Saudi Arabia" || location === "United Arab Emirates" || location === "Israel") ? (
                                    <div className="lead">
                                        <span className="opacity-70">$</span>
                                        <span className="ml-2 h2">3100</span>/mo
                                    </div>
                                ) : (
                                    <div className="lead">
                                        <span className="opacity-70">€</span>
                                        <span className="ml-2 h2">2900</span>/mo
                                    </div>
                                )
                            )}
                            <p className="mt-1">
                                No minimum commitment. <br />
                                Pause or cancel anytime
                            </p>
                            <div className="d-flex flex-column text-center pt-3 lead">
                                <Link to="https://calendly.com/adamfard/designstream" target="_blank" className="btn btn-lg btn-primary w-100 p-3 lead">
                                    Schedule a call
                                </Link>
                                <a href="/contact-us" target="_blank" className="lead text-primary mt-3">
                                    <u>Fill the form</u>
                                </a>
                            </div>
                            {/* <div className="d-flex flex-column text-center pt-3 lead">
                                <Link target="_blank" className="btn btn-lg bg-gray-1 w-100 p-3 lead opacity-80">
                                    Booked Out
                                </Link>
                                <p className="lead mt-3 mb-0 opacity-50">
                                    <strong>This plan is currently unavaible</strong>
                                </p>
                            </div> */}
                            {/* <div className="d-flex flex-column text-center pt-3 lead">
                                {
                                (location === "United States" || location === "Saudi Arabia" || location === "United Arab Emirates" || location === "Israel") ? (

                                    <Link to="https://buy.stripe.com/7sIg0w7QMbglfBu3ck" target="_blank" className="btn btn-lg btn-primary w-100 p-3 lead">
                                    Get Started
                                    </Link>

                                ):(
                                    <Link to="https://buy.stripe.com/28o4hO8UQ5W1fBu5kr" target="_blank" className="btn btn-lg btn-primary w-100 p-3 lead">
                                        Get Started
                                    </Link>
                                )}
                                    
                                <a href="https://calendly.com/adamfard/designstream" target="_blank" className="lead text-primary mt-3">
                                    <u>Book a call</u>
                                </a>
                            </div>
                            */}
                            <p className="h4 primary-3 pt-6 font-weight-bold">What's included:</p>

                            <ul className="list-unstyled">
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <span>
                                        <strong className="lead font-weight-bold">Unlimited UX and UI tasks</strong>
                                        <p>Completed one at a time.</p>
                                    </span>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <p>
                                        <strong className="lead font-weight-bold">Unlimited Revisions</strong>
                                    </p>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <span>
                                        <strong className="lead font-weight-bold">4 Days Turnaround Time</strong>
                                        <p>
                                            On average for simple tasks.
                                            <br /> We provide estimates for each task
                                        </p>
                                    </span>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <span>
                                        <strong className="lead font-weight-bold">Daily Communication</strong>
                                        <p>Slack, Figma, Notion</p>
                                    </span>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <p>
                                        <strong className="lead font-weight-bold">1 meeting every 4 weeks</strong>
                                    </p>
                                </li>
                                <li className="d-flex py-1">
                                    <div className="icon-xl mr-2">
                                        <IconCheck className="injected-svg icon primary-3" />
                                    </div>
                                    <span>
                                        <strong className="lead font-weight-bold">Project Management</strong>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 mx-auto justify-content-center text-center">
                        <div className="d-flex flex-row mx-auto rounded pt-2 pt-md-4 pb-4 px-3 justify-content-center align-items-center">
                            {/* <div className="d-flex">
                                <img width={200} src={ImgCustom}></img>
                            </div> */}
                            <div className="flex-column lead w-auto">
                                <h4 className="h1">Specific needs?</h4>
                                <p>Tailor your needs to a specific custom design project plan.</p>
                                <a href="/contact-us" target="_blank" className="lead text-primary mt-3 hover-arrow">
                                    Contact us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SubscriptionBlock;
